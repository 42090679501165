@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,400;0,700;1,400&display=swap');
$roboto: 'Roboto';

$font-family-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
$headings-font-family: $roboto, $font-family-sans-serif;
$btn-border-radius: 50rem;
$h1-font-size: 2.5rem;
$link-decoration: none;
@import "~bootstrap/scss/bootstrap";

@import "~@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "~@fortawesome/fontawesome-free/scss/regular.scss";
@import "~@fortawesome/fontawesome-free/scss/solid.scss";
@import "~@fortawesome/fontawesome-free/scss/brands.scss";
html,
body {
  height: 100%;
}

#root,
.app {
  height: 100%;
}

%horizontally-centered {
  @extend .justify-content-center;
}

%vertically-centered {
  @extend .justify-content-center;
  @extend .flex-column;
  @extend .align-items-center;
  height: 100%;
}

@import './styles';
@import './animation';
@import "./navMain";
@import './home';
@import './property';
@import './search';
@import './suggestions';


.list-with-action {
  .list-group-item {
    @extend .d-flex;
    @extend .justify-content-between;
  }
}

.intro,
.home {
  height: 100%;
  > .container {
    height: 100%;
    > .row {
      @extend %vertically-centered;
      height: 100%;
      > .col {
        @extend .col-lg-4;
        @extend .col-md-8;
        @extend .col-10;
      }
    }
  }
}

.property,
.order-title,
.search {
  > .container > .row {
    @extend %horizontally-centered;
    > .col {
      @extend .col-lg-8;
      @extend .col-md-8;
      @extend .col-12;
    }
  }
}

.order-title {
  @extend .fixed-bottom;
}


button {
  font-weight: normal;
}

.as-text {
  border: solid 0 #fff;
  background-color: transparent;
}

.as-button {
  @extend .btn;
  @extend .btn-primary;
  // border-radius: 100px;
  padding: .5rem 3rem;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu) {
  border-radius: $border-radius-pill 0 0 $border-radius-pill;
}

.top-bg-wrapper {
  // padding-top: 47px;
  padding-top: 1rem;
  color: #fff;
  a {
    color: inherit;
  }
  // padding: 6rem 0 2rem 0;
  padding-bottom: 2rem;
  margin-bottom: 2rem;
  .back {
    display: block;
    margin-bottom: 1rem;
  }
  > .container > .row {
    @extend %horizontally-centered;
    > .col {
      @extend .col-lg-8;
      @extend .col-md-8;
      @extend .col-12;
      height: 13rem;
    }
  }
  .h1-tag {
    font-weight: bold;
    font-size: .8rem;
    text-transform: uppercase;
    margin: 0;
  }
}

p:last-child {
  margin-bottom: 0;
}

.loading {
  text-align: center;
}

.full-width-video {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  // margin-bottom: $paragraph-margin-bottom*2;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
}