@import "~rc-drawer/assets/index.css";

.nav-main {
  .nav-top {
    @extend .justify-content-between;
    @extend .align-items-center;
    padding: 0.6rem;
    @extend .fixed-top;
    color: #fff;
    display: flex;
  }
  .nav-top.hide {
    display: none;
  }
}
.drawer-content-wrapper {
  background-color: $primary;
  color: #fff;
  a {
    color: #fff;
  }
  .drawer-content {
    padding: 1rem;
    li {
      text-align: right;
      border-bottom: 1px solid #ccc;
      padding: 1em 0;
    }
    li:last-child {
      border-bottom: 0px solid #ccc;
    }
  }
}
