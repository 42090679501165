.property {
  .top-bg-wrapper {
    > .container > .row > .col {
      @extend .d-flex;
      @extend .flex-column;
      @extend .justify-content-between;
    }
  }
  padding-bottom: 15rem;
  .tax-search-summary {
    table {
      // display: block;
      // overflow-x: auto;
      //white-space: nowrap;
    }
  }
  .co-info {
    margin-bottom: 2em;
  }
  .tax-info {
    .DataletTopHeading {
      font-weight: bold;
    }
    hr,
    table:first-child {
      display: none;
    }
    tbody tr td:nth-child(4),
    tbody tr td:nth-child(5),
    tbody tr td:nth-child(6),
    tbody tr td:nth-child(7) {
      display: none;
    }
    tbody,
    table {
      width: 100%;
      font-size: $small-font-size;
    }
    tbody tr td {
      padding: 0 .5em;
    }
  }
  .order-title-report {
    @extend .fixed-bottom;
    background: rgb(22,34,106);
    background-image: $blue-green-gradient;

    > .container > .row {
      @extend .justify-content-center;
      > .col {
        @extend .col-lg-6;
        @extend .col-md-8;
        @extend .col-10;
      }
    }

    padding-top: $grid-gutter-width*1;
    padding-bottom: $grid-gutter-width;
    text-align: center;
    color: #fff;
    .action {
      margin-bottom: $paragraph-margin-bottom;
      button {
        // font-size: $h4-font-size;
        text-transform: uppercase;
        font-weight: bold;
        padding: 0 2rem;
      }
    }
  }
}
