.search-form {
  position: relative;
}

.autocomplete--suggestions {
  &.list-group {
    position: absolute;
    width: 100%;
    overflow-y: auto;
    z-index: 100;
  }

  .list-group-item {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
