.home {
  background-image: linear-gradient(
    rgba(0, 0, 0, 0.5), 
    rgba(0, 0, 0, 0.5)
  ), url('./img/background_w1400.jpg');
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  h1 {
    margin-bottom: $paragraph-margin-bottom*4;
    img {
      max-width: 100%;
      @extend .flip-in-hor-bottom;
    }
  }
  .search-form {
    margin-bottom: $paragraph-margin-bottom*2;
  }
  p button {
    color: inherit;
    font-weight: bold;
    text-decoration: underline;
  }
}



.top-bg-wrapper,
.intro {
  background: rgb(22,34,106);
  background-image: $blue-green-gradient,
    url('./img/background_w1400.jpg');
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  h1 img {
    max-width: 100%;
    @extend .slit-in-horizontal;
  }
}

.home,
.intro {
  text-align: center;
  color: #fff;
}

.modal-intro-title-report {
  .full-width-video {
    margin-bottom: $paragraph-margin-bottom*2;
  }
}