.slit-in-horizontal {
	-webkit-animation: slit-in-horizontal 0.45s ease-out both;
	        animation: slit-in-horizontal 0.45s ease-out both;
}

@-webkit-keyframes slit-in-horizontal {
  0% {
    -webkit-transform: translateZ(-800px) rotateX(90deg);
            transform: translateZ(-800px) rotateX(90deg);
    opacity: 0;
  }
  54% {
    -webkit-transform: translateZ(-160px) rotateX(87deg);
            transform: translateZ(-160px) rotateX(87deg);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateZ(0) rotateX(0);
            transform: translateZ(0) rotateX(0);
  }
}
@keyframes slit-in-horizontal {
  0% {
    -webkit-transform: translateZ(-800px) rotateX(90deg);
            transform: translateZ(-800px) rotateX(90deg);
    opacity: 0;
  }
  54% {
    -webkit-transform: translateZ(-160px) rotateX(87deg);
            transform: translateZ(-160px) rotateX(87deg);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateZ(0) rotateX(0);
            transform: translateZ(0) rotateX(0);
  }
}

.flip-in-hor-bottom {
	-webkit-animation: flip-in-hor-bottom 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: flip-in-hor-bottom 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

 @-webkit-keyframes flip-in-hor-bottom {
  0% {
    -webkit-transform: rotateX(80deg);
            transform: rotateX(80deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0);
            transform: rotateX(0);
    opacity: 1;
  }
}
@keyframes flip-in-hor-bottom {
  0% {
    -webkit-transform: rotateX(80deg);
            transform: rotateX(80deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0);
            transform: rotateX(0);
    opacity: 1;
  }
}
